<template>
  <div>
    <div class="plaBox">
      <div class="top">
        <span class="span">平台类型</span>
        <el-radio-group v-model="radio" @change="radioChange">
          <el-radio :label="1">Android</el-radio>
          <el-radio :label="2">IOS</el-radio>
        </el-radio-group>
      </div>
      <div class="conter">
        <!-- 面包屑导航部分 -->
        <BreadNav></BreadNav>
<p>【主要功能】TiSDK是一套提供美颜、美型、贴纸、滤镜、美妆等特效的强大渲染引擎SDK</p>
        <p>【SDK名称】 TiSDK &nbsp; &nbsp;&nbsp;&nbsp;【支持平台】 Android/iOS </p>
            <p>【开发者信息】 南京拓幻智能科技有限公司 </p>
            <p>【个人信息处理规则】 <a href="https://tillusory.com/privacy">隐私政策</a>  &nbsp;&nbsp;&nbsp;  <a href="https://tillusory.com/developer/navPage/compliance/guide">合规使用说明</a> </p>
<br>
        <div class="down" style="margin-right: 50px">

          
          <h3>稳定版</h3>
          <p>TISDK 6.0.1</p>
          <a target="_blank" :href="stableHref">本地下载</a>
          
        </div>
        <div class="down">
          <h3>最新版</h3>
          <p>TISDK 6.0.1</p>
          <a target="_blank" :href="newestHref">本地下载</a>
        </div>
        <div class="table">
          <el-table
            ref="evtTable"
            :data="tableData"
            style="width: 95%"
            :show-header="false"
            @row-click="clickRowHandle"
          >
            <el-table-column prop="version" label="版本号"> </el-table-column>
            <el-table-column
              prop="date"
              label="日期"
              width="250px"
            ></el-table-column>
            <!-- 展开详情 -->
            <el-table-column type="expand">
              <template slot-scope="props">
                <h3 class="detail_h">版本描述</h3>
                <h4 class="detail_h" v-if="props.row.type === 'new'">
                  TiFancy 精准上新，欢迎尝鲜！
                </h4>
                <ul class="detail_ul">
                  <li v-for="(item, i) in props.row.describe" :key="i">
                    <span>{{ item.tit }}</span>
                    {{ item.text }}
                  </li>
                </ul>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BreadNav from "@/components/BreadNav/index"; //面包屑导航
export default {
  components: { BreadNav },
  data() {
    return {
      radio: 1,
      tableData: [],
      iosData: [
        {
          version: "v40100-latest",
          date: "更新时间：2021年9月15日",
          type: "new",
          iosStable: "https://gitee.com/tillusory/TiSDK_iOS/tree/master/",
          iosNewest: "https://gitee.com/tillusory/TiSDK_iOS",
          describe: [
            {
              tit: "【精准美肤】",
              text: "扩展精准美肤模块，更多高阶美颜特效 \n “精细磨皮”：精准识别皮肤，精细化磨皮，静美改变肤质状态； \n “精细粉嫩”：精准识别皮肤，让面部粉嫩红润更自然； \n “立体”：让面部美颜更具立体感； \n “黑眼圈”、“法令纹”、“⻥尾纹”：专业定向优化肤质细节；",
            },
            {
              tit: "【美妆唇彩】",
              text: "增加自然贴合的美妆唇彩功能",
            },
            {
              tit: "【新增抖动】",
              text: "增加8款多样⻛格的抖动滤镜特效",
            },
            {
              tit: "【美颜美型特效优化】",
              text: "优化升级美颜美型特效",
            },
          ],
        },
        {
          version: "V31105-40700",
          date: "更新时间：2021年5月1日",
          type: "",
          describe: [
            {
              tit: "【手势识别】",
              text: "精准识别多种手势，呈现不一样的互动特效，提升趣味性，高端机效果更好哟！",
            },
            {
              tit: "【精准美肤】",
              text: "在肤质的实时识别与检测的基础上实现精细化的美肤体验，呈现更精致的美！",
            },
            {
              tit: "【低端机适配】",
              text: "为低端机提供一键美颜的风格选择，满足低性能机型的流畅运行！",
            },
            {
              tit: "【分辨率调节】",
              text: "提供三种视频采集分辨率下实时美颜效果呈现，更贴心！",
            },
            {
              tit: "【手动对焦】",
              text: "在手机摄像头支持对焦的情况下，提供手动对焦的选项，提供更有层次的视觉体验！",
            },
            {
              tit: "【绿幕抠图可调节】",
              text: "针对绿幕抠图提供三个可调节的参数，满足更多变的场景需求，让功能更强大！",
            },
            {
              tit: "【性能升级】",
              text: "面向底层技术的优化升级，带来更快更稳定的性能提升！",
            },
          ],
        },
        {
          version: "V30900-40400",
          date: "更新时间：2021年4月15日",
          type: "",
          describe: [
            {
              tit: "【更自然的美白特效】",
              text: "开播时美白更自然，更清晰，让MM们收获更多的赞美和礼物。",
            },
            {
              tit: "【更多样的微整形特效】",
              text: " 新增了脸部、眼部、嘴巴、眉毛等脸部的微整形特效，每个细节都在你的掌控之中，给你更细节的美。",
            },
            {
              tit: "【更精致的滤镜特效】",
              text: "更精致的滤镜可以让主播们输出更贴近电影质感的画面；而细致的滤镜分类可以带来更精准的使用体验。",
            },
            {
              tit: "【更用心的UI升级】",
              text: "  贴纸、互动、礼物特效的ICON升级后让面板更有质感；滤镜的选择面板将滤镜分类融入其中，方便更快定位到你想要的效果；",
            },
            {
              tit: "【手动对焦】",
              text: "在手机摄像头支持对焦的情况下，提供手动对焦的选项，提供更有层次的视觉体验！",
            },
            {
              tit: "【更细节的内部优化】",
              text: " 更出色的算法优化和升级让你的手机更流畅的运行。",
            },
          ],
        },
      ],
      andData: [
        {
          version: "v40100-latest",
          date: "更新时间：2021年9月15日",
          type: "new",
          andStable: "https://gitee.com/tillusory/TiSDK_Android/tree/master/",
          andNewest: "https://gitee.com/tillusory/TiSDK_Android",
          describe: [
            {
              tit: "【精准美肤】",
              text: "扩展精准美肤模块，更多高阶美颜特效 \n “精细磨皮”：精准识别皮肤，精细化磨皮，静美改变肤质状态； \n “精细粉嫩”：精准识别皮肤，让面部粉嫩红润更自然； \n “立体”：让面部美颜更具立体感； \n “黑眼圈”、“法令纹”、“⻥尾纹”：专业定向优化肤质细节；",
            },
            {
              tit: "【美妆唇彩】",
              text: "增加自然贴合的美妆唇彩功能",
            },
            {
              tit: "【新增抖动】",
              text: "增加8款多样⻛格的抖动滤镜特效",
            },
            {
              tit: "【美颜美型特效优化】",
              text: "优化升级美颜美型特效",
            },
          ],
        },
        {
          version: "V31105-40700",
          date: "更新时间：2021年5月1日",
          type: "",
          describe: [
            {
              tit: "【手势识别】",
              text: "精准识别多种手势，呈现不一样的互动特效，提升趣味性，高端机效果更好哟！",
            },
            {
              tit: "【精准美肤】",
              text: "在肤质的实时识别与检测的基础上实现精细化的美肤体验，呈现更精致的美！",
            },
            {
              tit: "【低端机适配】",
              text: "为低端机提供一键美颜的风格选择，满足低性能机型的流畅运行！",
            },
            {
              tit: "【分辨率调节】",
              text: "提供三种视频采集分辨率下实时美颜效果呈现，更贴心！",
            },
            {
              tit: "【手动对焦】",
              text: "在手机摄像头支持对焦的情况下，提供手动对焦的选项，提供更有层次的视觉体验！",
            },
            {
              tit: "【绿幕抠图可调节】",
              text: "针对绿幕抠图提供三个可调节的参数，满足更多变的场景需求，让功能更强大！",
            },
            {
              tit: "【性能升级】",
              text: "面向底层技术的优化升级，带来更快更稳定的性能提升！",
            },
          ],
        },
        {
          version: "V30900-40400",
          date: "更新时间：2021年4月15日",
          type: "",
          describe: [
            {
              tit: "【更自然的美白特效】",
              text: "开播时美白更自然，更清晰，让MM们收获更多的赞美和礼物。",
            },
            {
              tit: "【更多样的微整形特效】",
              text: " 新增了脸部、眼部、嘴巴、眉毛等脸部的微整形特效，每个细节都在你的掌控之中，给你更细节的美。",
            },
            {
              tit: "【更精致的滤镜特效】",
              text: "更精致的滤镜可以让主播们输出更贴近电影质感的画面；而细致的滤镜分类可以带来更精准的使用体验。",
            },
            {
              tit: "【更用心的UI升级】",
              text: "  贴纸、互动、礼物特效的ICON升级后让面板更有质感；滤镜的选择面板将滤镜分类融入其中，方便更快定位到你想要的效果；",
            },
            {
              tit: "【手动对焦】",
              text: "在手机摄像头支持对焦的情况下，提供手动对焦的选项，提供更有层次的视觉体验！",
            },
            {
              tit: "【更细节的内部优化】",
              text: " 更出色的算法优化和升级让你的手机更流畅的运行。",
            },
          ],
        },
      ],
      stableHref: "",
      newestHref: "",
    };
  },
  created() {
    this.radioChange(this.radio);
  },
  methods: {
    // 单选按钮切换
    radioChange(val) {
      if (val === 1) {
        this.href = this.andDown;
        this.tableData = this.andData;
        this.stableHref = this.andData[0].andStable;
        this.newestHref = this.andData[0].andNewest;
      } else {
        this.href = this.iosDown;
        this.tableData = this.iosData;
        this.stableHref = this.iosData[0].iosStable;
        this.newestHref = this.iosData[0].iosNewest;
      }
    },
    // 点击行展开
    clickRowHandle(row) {
      let $table = this.$refs.evtTable;
      this.tableData.map((item) => {
        if (row.version != item.version) {
          $table.toggleRowExpansion(item, false);
        }
      });
      $table.toggleRowExpansion(row);
    },
  },
};
</script>
<style lang="scss" scope>
.plaBox {
  width: 1140px;
  margin: auto;
  .top {
    height: 65px;
    line-height: 75px;
    .span {
      margin-left: 32px;
      margin-right: 80px;
      font-size: 16px;
      font-weight: 400;
      color: #222222;
      line-height: 30px;
    }
  }
  .conter {
    width: 100%;
    height: 696px;
    background: #ffffff;
    padding: 0 36px;
    margin-bottom: 42px;
    overflow-y: scroll;
    .down {
      display: inline-block;
      width: 500px;
      height: 212px;
      background: #fafcff;
      border: 1px solid #eeeeee;
      text-align: center;
      h3 {
        font-size: 16px;
        font-weight: 500;
        color: #212121;
        line-height: 24px;
        margin-top: 49px;
      }
      p {
        font-size: 14px;
        font-weight: 400;
        color: #666666;
        line-height: 60px;
      }
      a {
        display: inline-block;
        width: 160px;
        height: 40px;
        line-height: 40px;
        border: 1px solid #4c8cf5;
        border-radius: 4px;
        font-size: 16px;
        font-weight: 400;
        color: #4c8cf5;
      }
      a:hover {
        background: #4c8cf5;
        border: 1px solid #4c8cf5;
        color: #ffffff;
      }
    }
    .table {
      margin-top: 36px;
      li {
        list-style-type: decimal;
        line-height: 24px;
        white-space: pre-wrap;
        span {
          display: block;
          font-size: 14px;
          font-weight: 400;
          line-height: 24px;
        }
      }
    }
  }
}
</style>